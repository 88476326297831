export const categories = [
  { title: "Featured", key: "featured" },
  { title: "Color-Pencil", key: "colorpencil" },
  { title: "Digital-Art", key: "digitalart" },
  { title: "Pencil", key: "pencil" },
  { title: "Watercolor", key: "watercolor" },
  // { title: "Oil-Pastel", key: "oilpastel" },
  // { title: "Coffee-Art", key: "coffee" },
  // { title: "Acrylic", url: "#" },
  // { title: "Soft-Pastel", key: "softpastel" },
  // { title: "Charcoal", key: "charcoal" },
  // { title: "Pen", key: "pen" },
  // { title: "Others", key: "others" },
  { title: "All", key: "all" },
];

export const picsDB = [
  {
    "img": "23-riya-seyon.png",
    "title": "Riya Seyon",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "landscape"
  },
  {
    "img": "23-vinayagar.png",
    "title": "Lord Ganesha",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "portrait"
  },
  {
    "img": "23-bharathanattiyam.png",
    "title": "Bharathanattiyam",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "landscape"
  },
  {
    "img": "23-buddha.png",
    "title": "Buddha",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "portrait"
  },
  {
    "img": "23-buddha2.png",
    "title": "Buddha",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "portrait"
  },
  {
    "img": "23-cake.png",
    "title": "Cake",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "landscape"
  },
  {
    "img": "23-christmas-girl.png",
    "title": "Christmas Girl",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart"],
    "year": 2024,
    "mode": "portrait"
  },
  {
    "img": "23-draganfruit.png",
    "title": "Dragonfruit",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "portrait"
  },
  {
    "img": "23-gandhiji.png",
    "title": "Gandhiji",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "landscape"
  },
  {
    "img": "23-girl-with-dear.png",
    "title": "Girl with Deer",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "landscape"
  },
  {
    "img": "23-island-babybuddha.png",
    "title": "Baby Buddha",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "landscape"
  },
  {
    "img": "23-king.png",
    "title": "King",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "portrait"
  },
  {
    "img": "23-little-girl.png",
    "title": "Little Girl",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart"],
    "year": 2024,
    "mode": "portrait"
  },
  {
    "img": "23-sanyasi2.png",
    "title": "Sanyasi",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "portrait"
  },
  {
    "img": "23-uber-boy.png",
    "title": "Uber Boy",
    "medium": "Digital Art - Procreate",
    "category": ["digitalart", "featured"],
    "year": 2024,
    "mode": "landscape"
  },
  {
    img: "22-mahabali.jpg",
    title: "King Mahabali",
    medium: "Digital Art - Procreate",
    category: ["digitalart", "featured"],
    year: 2023,
    mode: "portrait",
  },
  {
    img: "22-baby-riya.png",
    title: "My Baby - Riya",
    medium: "Digital Art - Procreate",
    category: ["digitalart", "featured"],
    year: 2023,
    mode: "landscape",
  },
  {
    img: "22-rajastani.png",
    title: "Rajasthani",
    medium: "Digital Art - Procreate",
    category: ["digitalart"],
    year: 2023,
    mode: "portrait",
  },
  {
    img: "22-bharathiyar.png",
    title: "Poet Bharathiyar",
    medium: "Digital Art - Procreate",
    category: ["digitalart"],
    year: 2023,
    mode: "portrait",
  },
  {
    img: "22-kerala.png",
    title: "Kerala Scenary",
    medium: "Digital Art - Procreate",
    category: ["digitalart", "featured"],
    year: 2023,
    mode: "landscape",
  },
  {
    img: "22-man-red.png",
    title: "Random Man",
    medium: "Digital Art - Procreate",
    category: ["digitalart"],
    year: 2023,
    mode: "landscape",
  },
  {
    img: "22-office-man.png",
    title: "Office Man",
    medium: "Digital Art - Procreate",
    category: ["digitalart"],
    year: 2023,
    mode: "portrait",
  },
  {
    img: "22-priest.png",
    title: "Saint",
    medium: "Digital Art - Procreate",
    category: ["digitalart"],
    year: 2023,
    mode: "landscape",
  },
  {
    img: "22-avatar.png",
    title: "Avatar",
    medium: "Digital Art - Procreate",
    category: ["digitalart", "featured"],
    year: 2023,
    mode: "portrait",
  },

  {
    img: "22-ricksha-man.png",
    title: "Rickshaw Man",
    medium: "Digital Art - Procreate",
    category: ["digitalart", "featured"],
    year: 2023,
    mode: "landscape",
  },
  {
    img: "22-rino.png",
    title: "Rhinoceros",
    medium: "Digital Art - Procreate",
    category: ["digitalart"],
    year: 2023,
    mode: "landscape",
  },

  {
    img: "22-self-portrait.png",
    title: "Self Portrait",
    medium: "Digital Art - Procreate",
    category: ["digitalart"],
    year: 2023,
    mode: "landscape",
  },
  {
    img: "22-wanderer.png",
    title: "Wanderer",
    medium: "Digital Art - Procreate",
    category: ["digitalart", "featured"],
    year: 2023,
    mode: "portrait",
  },

  {
    img: "21-digitalart-firewood2.jpg",
    title: "Burning Firewood",
    medium: "Digital Art - Procreate",
    category: ["digitalart"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-watercolor-window.jpg",
    title: "Window Box Flowers",
    medium: "Pen & Watercolor",
    category: ["watercolor", "featured"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "21-watercolor-door.jpg",
    title: "Doorstep",
    medium: "Pen & Watercolor",
    category: ["watercolor", "featured"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-watercolor-tree2.jpg",
    title: "Tree",
    medium: "Pen & Watercolor",
    category: ["watercolor"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-watercolor-book2.jpg",
    title: "Books",
    medium: "Pen & Watercolor",
    category: ["watercolor"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "21-pencil-waterbottle.jpg",
    title: "Waterbottle",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-women.jpg",
    title: "women",
    category: [],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-trib-kid.jpg",
    title: "Tribe kid",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-teacup.jpg",
    title: "Tea cup",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "21-pencil-thirisulam.jpg",
    title: "Thirisulam",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-router.jpg",
    title: "Router",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "22-shaan.png",
    title: "My Friend - Shaan",
    medium: "Digital Art - Procreate",
    category: ["digitalart", "featured"],
    year: 2023,
    mode: "portrait",
  },

  {
    img: "21-pencil-mountain3.jpg",
    title: "Mountain",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-portrait5.jpg",
    title: "Portrait",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-charcoal-kingkong.jpg",
    title: "Kingkong",
    medium: "Charcoal, Graphite Pencil",
    category: ["pencil", "featured"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-clip.jpg",
    title: "Clip",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },

  {
    img: "21-charcoal-jug.jpg",
    title: "Jug",
    medium: "Charcoal, Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-pot.jpg",
    title: "Pot",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "21-pencil-pear.jpg",
    title: "Pear",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "18-oilpastel-birds-on-tree.jpg",
    title: "Birds on Tree-branch",
    medium: "Oil Pastel",
    category: ["colorpencil", "featured"],
    year: 2018,
    mode: "landscape",
  },
  {
    img: "21-pencil-mountain2.jpg",
    title: "Mountain",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "21-pencil-elephent.jpg",
    title: "Elephent",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "21-watercolor-lotus2.jpg",
    title: "Lotus",
    medium: "Watercolor",
    category: ["watercolor"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-banana2.jpg",
    title: "Banana",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-portrait4.jpg",
    title: "Bhagat Singh",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },

  {
    img: "21-watercolor-environment.jpg",
    title: "Environment",
    medium: "Watercolor",
    category: ["watercolor"],
    year: 2021,
    mode: "landscape",
  },

  {
    img: "21-pencil-wood.jpg",
    title: "Half Cut Tree",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "21-pencil-parrot.jpg",
    title: "Parrots",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-portrait.jpg",
    title: "Portrait",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "18-oilpastel-bridge-couple.jpg",
    title: "Couple on bridge",
    medium: "Oil Passtel",
    category: ["colorpencil", "featured"],
    year: 2018,
    mode: "landscape",
  },
  {
    img: "21-pencil-home.jpg",
    title: "Home",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-deer.jpg",
    title: "Deer",
    medium: "Graphite Pencil",
    category: ["pencil", "featured"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-bull.jpg",
    title: "Cow",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "21-pencil-brother.jpg",
    title: "My Brother",
    medium: "Graphite Pencil, Crayon",
    category: ["pencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "21-pencil-bridge.jpg",
    title: "Bridge",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "20-watercolor-vinayaka.jpg",
    title: "Lord Ganesha",
    medium: "Watercolor",
    category: ["watercolor"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "20-watercolor-buddha.jpg",
    title: "Buddha",
    medium: "Pen & Watercolor",
    category: ["watercolor"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "20-colorpencil-hanuman.jpg",
    title: "Jai Hanuman",
    medium: "Color Pencil",
    category: ["colorpencil", "watercolor", "featured"],
    year: 2021,
    mode: "portrait",
  },

  {
    img: "16-colorpencil-krishna-radha.jpg",
    title: "Radha Krishna",
    medium: "Color Pencil",
    category: ["colorpencil", "featured"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "16-colorpencil-amman.jpg",
    title: "Samayapuram Amman",
    medium: "Color Pencil",
    category: ["colorpencil", "featured"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "15-pencil-stilllife-book.jpg",
    title: "Books",
    medium: "Graphite Pencil",
    category: ["pencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "15-colorpencil-young-krishna.jpg",
    title: "Young Krishna",
    medium: "Color Pencil",
    category: ["colorpencil", "featured"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "15-colorpencil-watermelon.jpg",
    title: "Watermelon",
    medium: "Color Pencil",
    category: ["colorpencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "15-colorpencil-parrot.jpg",
    title: "Parrot",
    medium: "Color Pencil",
    category: ["colorpencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "15-colorpencil-kiwi.jpg",
    title: "Kiwi",
    medium: "Color Pencil",
    category: ["colorpencil"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "15-colorpencil-banana.jpg",
    title: "Banana",
    medium: "Color Pencil",
    category: ["colorpencil", "featured"],
    year: 2021,
    mode: "landscape",
  },
  {
    img: "15-colorpencil-baby-krishna.jpg",
    title: "Baby Krishna",
    medium: "Color Pencil",
    category: ["colorpencil"],
    year: 2021,
    mode: "portrait",
  },
  {
    img: "15-colorpencil-apple.jpg",
    title: "Apple",
    medium: "Color Pencil",
    category: ["colorpencil"],
    year: 2021,
    mode: "landscape",
  },
];
